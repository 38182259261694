<template>
  <div class="new-member">
    <v-title>
      <Breadcrumb separator=">">
        <Breadcrumb-item><i class="icon-home"></i>会员档案</Breadcrumb-item>
        <Breadcrumb-item>新建档案</Breadcrumb-item>
      </Breadcrumb>
    </v-title>

    <div class="content">
      <!-- <v-member-panel></v-member-panel> -->
      <v-member-info></v-member-info>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
// import vMemberPanel from 'pages/member/common/member-fixed-panel'
import vMemberInfo from 'pages/member/new-info';
export default {
	components: {
		'v-title': vTitle,
		// 'v-member-panel': vMemberPanel,
		'v-member-info': vMemberInfo,
	},
};
</script>
<style lang="css" scoped>
</style>
