<template>
  <Form :label-width="150" :model="Member" :rules="ruleValidate" ref="Member">
    <div class="card-container">
      <Card>
        <p slot="title">基本信息</p>
        <div class="card-box clearfix">
          <Row>
            <Col :lg="{span: 2}" :md="{span: 4}" class="tac">
              <div class="card-avatar">
                <Upload
                  ref="upload"
                  :format="['jpg', 'jpeg', 'png']"
                  action="/api/member/photo/upload"
                  :on-success="handleUploadSuccess"
                  :show-upload-list="false"
                  :on-format-error="handleFormatError"
                  name="photo">
                  <div class="card-img">
                    <template v-if="avatarShow.status === 'finished'">
                      <img :src="photoData.photo" alt="" width="80px" height="80px" title="点击更换头像">
                    </template>
                    <template v-else>
                      <img src="@/assets/img/bg_avatar.png" alt="" width="80px" height="80px" title="点击上传头像">
                    </template>
                  </div>
                  <p class="card-text">头像</p>
                </Upload>
              </div>
            </Col>
            <Col :lg="{span: 22}" :md="{span: 20}">
              <div class="card-info">
                <Row>
                  <Col :span="6">
                    <Form-item label="身份证号：" prop="id_card">
                      <Input v-model.trim="Member.id_card" placeholder="请输入身份证号码" @on-blur="handleIDBlur"></Input>
                    </Form-item>
                  </Col>
                  <Col :span="6">
                    <Form-item label="姓名：" prop="name">
                      <Input v-model.trim="Member.name" :maxlength="10" placeholder="请输入姓名"></Input>
                    </Form-item>
                  </Col>
                  <Col :span="6">
                    <Form-item label="性别：" prop="gender">
                      <Select disabled class="w80" v-model="Member.gender">
                        <Option value="0">男</Option>
                        <Option value="1">女</Option>
                      </Select>
                    </Form-item>
                  </Col>
                  <Col :span="6">
                    <Form-item label="民族：" prop="nation">
                      <Select clearable placeholder="请选择" v-model="Member.nation">
                        <Option v-for="item in nationList" :value="item" :key="item">{{item}}</Option>
                      </Select>
                    </Form-item>
                  </Col>
                </Row>

                <Row>
                  <Col :span="6">
                    <Form-item label="生日：">
                      {{ Member.birthday }}
                    </Form-item>
                  </Col>
                  <Col :span="6">
                    <Form-item label="年龄：">
                      {{ Member.age }} 岁
                    </Form-item>
                  </Col>
                  <Col :span="6">
                    <Form-item label="建档日期：">
                      {{ nowDate }}
                    </Form-item>
                  </Col>
                  <Col :span="6">
                    <Form-item label="服务员工：">
                      {{ staff }}
                    </Form-item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
    <div class="new-member-info">
      <Card>
        <p slot="title">详细信息</p>
        <div class="info-box">
          <!-- Boss可修改 -->
          <Row v-if="!powerShow">
            <Col :span="6">
              <Form-item label="省份：" prop="pid">
                <Select filterable clearable v-model="Member.pid" placeholder="请选择" @on-change="changePro">
                  <Option :value="v.id" v-for="v in proList" :key="v.id">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col :span="6">
              <Form-item label="城市：" prop="cid">
                <Select filterable clearable v-model="Member.cid" placeholder="请选择" @on-change="changeCity" @click.native="changeBossCity">
                  <Option :value="v.id" v-for="v in cityList" :key="v.id">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col :span="6">
              <Form-item label="门店：" prop="branch_id" @click.native="changeBossBranch">
                <Select  filterable clearable v-model="Member.branch_id" placeholder="请选择">
                  <Option :value="v.id" v-for="v in storeList" :key="v.id">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col :span="6">
              <Form-item label="设备使用情况：" prop="device_type">
                <Select clearable v-model="Member.device_type" placeholder="请选择">
                  <Option :value="v.value" v-for="v in deviceList" :key="v.value">{{ v.label }}</Option>
                </Select>
              </Form-item>
            </Col>
          </Row>

          <!-- 员工不可修改状态 -->
          <Row v-else>
            <Col :span="6">
              <Form-item label="省份：">
                <Input v-model="pro_name" disabled></Input>
              </Form-item>
            </Col>
            <Col :span="6">
              <Form-item label="城市：">
                <Input v-model="city_name" disabled></Input>
              </Form-item>
            </Col>
            <Col :span="6">
              <Form-item label="门店：">
                <Input v-model="branch_name" disabled></Input>
              </Form-item>
            </Col>
            <Col :span="6">
              <Form-item label="设备使用情况：" prop="device_type">
                <Select clearable v-model="Member.device_type" placeholder="请选择">
                  <Option :value="v.value" v-for="v in deviceList" :key="v.value">{{ v.label }}</Option>
                </Select>
              </Form-item>
            </Col>
          </Row>

          <Row>
            <Col :span="6">
              <Form-item label="籍贯：">
                <Cascader :data="nativePlaceList" v-model="native_place" @on-change="handleNative"></Cascader>
              </Form-item>
            </Col>
            <Col :span="6">
              <Form-item label="家庭住址：">
                <Input v-model="Member.address" placeholder="请输入家庭住址"></Input>
              </Form-item>
            </Col>
            <Col :span="6">
              <Form-item label="文化程度：" prop="education">
                <Select clearable placeholder="请选择" v-model="Member.education" @on-change="handleEducation">
                  <Option v-for="item in educationList" :value="item.id" :key="item.id">{{item.name}}</Option>
                </Select>
              </Form-item>
            </Col>
          </Row>

          <Row>
            <Col :span="6">
              <Form-item label="App 账户(手机号)：" prop="phone">
                <Input v-model.trim="Member.phone" placeholder="请输入"></Input>
              </Form-item>
            </Col>
            <Col :span="6">
              <Form-item label="身高：" prop="height">
                <InputNumber
                  :active-change="false"
                  v-model="Member.height"
                  placeholder="请输入"
                  :max="200"
                  :min="140"></InputNumber>&nbsp;&nbsp;cm
              </Form-item>
            </Col>
            <Col :span="6">
              <Form-item label="体重：" prop="weight">
                <InputNumber
                  :active-change="false"
                  v-model="Member.weight"
                  placeholder="请输入"
                  :max="120"
                  :min="35"></InputNumber>&nbsp;&nbsp;kg
              </Form-item>
            </Col>
          </Row>

          <Row>
            <Col :span="6">
              <Form-item label="备用手机号：" prop="bysjh">
                <Input v-model.trim="Member.bysjh" placeholder="请输入"></Input>
              </Form-item>
            </Col>
            <Col :span="6">
              <Form-item label="婚姻状况：" prop="marita">
                <Select clearable placeholder="请选择" v-model="Member.marita" @on-change="handleMarita">
                  <Option v-for="item in maritaType" :value="item.id" :key="item.id">{{item.name}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col :span="6">
              <Form-item label="宗教信仰：">
                <Select clearable placeholder="请选择" v-model="Member.religion">
                  <Option v-for="item in beliefList" :value="item" :key="item">{{item}}</Option>
                </Select>
              </Form-item>
            </Col>
          </Row>

          <Row>
            <Col :span="6">
              <Form-item label="职业：">
                <Select clearable placeholder="请选择" v-model="Member.job">
                  <Option v-for="item in jobList" :value="item" :key="item">{{item}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col :span="6">
              <Form-item label="原工作单位：">
                <Input v-model.trim="Member.work_unit" placeholder="请输入原工作单位"></Input>
              </Form-item>
            </Col>
            <Col :span="6">
              <Form-item label="职称：">
                <Select clearable placeholder="请选择" v-model="Member.positional">
                  <Option v-for="item in pTitleList" :value="item" :key="item">{{item}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col :span="6">
              <Form-item label="原职务：">
                <Input v-model.trim="Member.job_p" placeholder="请输入原职务"></Input>
              </Form-item>
            </Col>
          </Row>

          <Row>
            <Col span="12">
              <Form-item label="顾客类型：" prop="member_type1">
                <Row>
                  <Col span="11">
                    <Select clearable v-model="Member.member_type1" placeholder="请选择" @on-change="changeType">
                      <Option :value="v.id" v-for="v in typeList" :key="v.id">{{v.name}}</Option>
                    </Select>
                  </Col>
                  <Col span="12" offset="1">
                    <Select clearable multiple  v-model="type_item_ids" placeholder="请选择" @on-change="changeTypeItem" @click.native="changeItems">
                      <Option :value="v.id" v-for="v in typeItemList" :key="v.id">{{v.name}}</Option>
                    </Select>
                  </Col>
                </Row>
              </Form-item>
            </Col>
            <Col :span="6">
              <Form-item label="介绍人：" prop="jsr_id">
                <Select
                  clearable
                  filterable
                  v-model="Member.jsr_id"
                  placeholder="请选择"
                >
                  <Option :value="v.id" v-for="v in userList" :key="v.id">
                    {{ v.name + ' - ' + v.branch_name }}
                  </Option>
                </Select>
              </Form-item>
            </Col>
          </Row>
          <Row>
            <Col :span="6">
              <Form-item label="与子女同住：" prop="conhabit">
                <Select clearable placeholder="请选择" v-model="Member.conhabit" @on-change="handleConhabit">
                  <Option value="1">是</Option>
                  <Option value="0">否</Option>
                </Select>
              </Form-item>
            </Col>
            <Col :span="6">
              <span class="point"></span>
              <Form-item label="密度：" v-if="choConhabit" :class="{'ivu-form-item-error': conhabitEdit}">
                <Cascader :data="densityGradeList" v-model="densityData" @on-change="handleChangeDensity"></Cascader>
                <div class="ivu-form-item-error-tip" v-show="conhabitEdit">请选择密度</div>
              </Form-item>
              <Form-item label="密度：" v-else>
                <Cascader :data="densityGradeList" disabled v-model="densityData"></Cascader>
              </Form-item>
            </Col>
            <Col :span="6">
              <Form-item label="ABO属性：" prop="ABO_type">
                <Select clearable v-model="Member.ABO_type" placeholder="请选择">
                  <Option :value="v.value" v-for="v in ABOList" :key="v.value">{{ v.label }}</Option>
                </Select>
              </Form-item>
            </Col>
          </Row>
          <Row>
            <Col :span="6">
              <Form-item label="兴趣活动：">
                <Select placeholder="请选择" filterable multiple v-model="interestArr" @on-change="handleInterest">
                  <Option v-for="item in interestList" :value="item" :key="item">{{item}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col :span="6">
              <Form-item label="社会交际：">
                <Select placeholder="请选择" filterable multiple v-model="socialArr" @on-change="handleSocial">
                  <Option v-for="item in socialList" :value="item" :key="item">{{item}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col :span="6">
              <Form-item label="特殊贡献：">
                <Select placeholder="请选择" filterable multiple v-model="contributionArr" @on-change="handleContribution">
                  <Option v-for="item in contributionList" :value="item" :key="item">{{item}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col :span="6">
              <Form-item label="特殊荣誉：">
                <Select placeholder="请选择" filterable multiple v-model="honorArr" @on-change="handleHonor">
                  <Option v-for="item in honorList" :value="item" :key="item">{{item}}</Option>
                </Select>
              </Form-item>
            </Col>
          </Row>

          <Row>
            <Col :span="6">
              <Form-item label="紧急联系人姓名：">
                <Input v-model.trim="Member.mate_name" placeholder="请输入紧急联系人姓名"></Input>
              </Form-item>
            </Col>
            <Col :span="6">
              <Form-item label="联系人电话：">
                <Input v-model.trim="Member.mate_card" placeholder="请输入联系人电话"></Input>
              </Form-item>
            </Col>
            <Col :span="6">
              <Form-item label="与联系人关系：">
                <Input v-model="Member.mate_postion" placeholder="请输入..."></Input>
              </Form-item>
            </Col>
            <Col :span="6">
              <Form-item label="联系人职业：">
                <Select clearable placeholder="请选择" v-model="Member.mate_job">
                  <Option v-for="item in jobList" :value="item" :key="item">{{item}}</Option>
                </Select>
              </Form-item>
            </Col>
          </Row>
        </div>
        <div class="symbol-box tac">
          <Button type="primary" size="large" @click="handleBack">返回</Button>
          <Button type="success" size="large" @click="handleSubmit('Member')">保存</Button>
        </div>
      </Card>
    </div>
  </Form>
</template>

<script type="text/ecmascript-6">
import City from '@/mixins/City';
import MemberService from '@/services/memberService';
import familyService from '@/services/familyService';

export default {
	data() {
		const validateName = (rule, value, callback) => {
			if (!/^[\u4E00-\u9FA5A-Za-z]{2,4}$/.test(value)) {
				callback(new Error('请输入真实姓名'));
			} else {
				callback();
			}
		};
		const validateIdCard = (rule, value, callback) => {
			if (
				!/^[1-9][0-9]{5}([1][9][2][2-9]|[1][9][3-9][0-9]|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/.test(
					value,
				)
			) {
				callback(new Error('请输入有效的二代身份证号码'));
			} else {
				callback();
			}
		};
		const validateCity = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请选择所属城市'));
			} else {
				callback();
			}
		};
		const validatePhone = (rule, value, callback) => {
			if (!/^1[3456789]\d{9}$/.test(value)) {
				callback(new Error('请输入有效手机号码'));
			} else {
				callback();
			}
		};
		const validateBranch = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请选择所属分院'));
			} else {
				callback();
			}
		};
		const validateType = (rule, value, callback) => {
			if (!this.Member.member_type1) {
				callback(new Error('请选择顾客类型'));
			} else {
				callback();
			}
		};
		const validateHeight = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入身高'));
			} else if (Number.isNaN(Number(value))) {
				callback(new Error('请输入数字'));
			} else {
				callback();
			}
		};
		const validateWeight = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入体重'));
			} else if (Number.isNaN(Number(value))) {
				callback(new Error('请输入数字'));
			} else {
				callback();
			}
		};
		const validateDevice = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请选择设备使用情况'));
			} else {
				callback();
			}
		};
		return {
			Member: {
				head_id: '',
				id_card: '',
				name: '',
				member_type_id: '',
				member_card_id: '',
				gender: '',
				address: '',
				nation: '汉族',
				birthday: '',
				pid: '',
				cid: '',
				member_type1: '',
				member_type2: [],
				branch_id: '',
				age: 0,
				grade_id: '',
				density_grade: '',
				density_detail: '',
				conhabit: '',
				native_place: '',
				religion: '',
				education: '',
				phone: '',
				bysjh: '',
				marita: '',
				positional: '',
				work_unit: '',
				job: '',
				job_p: '',
				height: null,
				weight: null,
				interest: [],
				social: [],
				contribution: [],
				honor: [],
				mate_name: '',
				mate_job: '',
				mate_postion: '',
				mate_card: '',
				device_type: '1',
				ABO_type: '',
			},
			userList: [],
			type_item_ids: [],
			typeList: [],
			typeItemList: [],
			typeItem: {},
			CityBranch: {},
			native_place: [],
			gender: '',
			branch: [],
			branchData: [],
			nationList: [],
			conhabit: '',
			ABO_type: '',
			densityGradeList: [],
			densityData: [],
			educationList: [],
			beliefList: [],
			maritaType: [],
			jobList: [],
			gradeList: [],
			interestList: [],
			socialList: [],
			contributionList: [],
			honorList: [],
			interestArr: [],
			socialArr: [],
			contributionArr: [],
			honorArr: [],
			pTitleList: [],
			nativePlaceList: [],
			UrbanList: [],
			memberTypeData: [],
			member_type_Array: [],
			avatarShow: '',
			photoData: {
				photo: '',
				id: '',
			},
			choConhabit: false,
			conhabitEdit: false,
			cardEdit: false,
			ruleValidate: {
				id_card: [{ required: true, validator: validateIdCard, trigger: 'blur' }],
				name: [{ required: true, validator: validateName, trigger: 'blur' }],
				gender: [{ required: true, message: '请选择性别', trigger: 'change' }],
				cid: [{ required: true, validator: validateCity, trigger: 'change' }],
				branch_id: [{ required: true, validator: validateBranch, trigger: 'change' }],
				phone: [{ required: true, validator: validatePhone, trigger: 'blur' }],
				member_type1: [{ required: true, validator: validateType, trigger: 'change' }],
				nation: [{ required: true, message: '请选择民族', trigger: 'change' }],
				weight: [{ required: true, validator: validateWeight, trigger: 'blur' }],
				height: [{ required: true, validator: validateHeight, trigger: 'blur' }],
				device_type: [{ required: true, validator: validateDevice, trigger: 'change' }],
			},
			power: [],
			powerShow: false,
			pro_name: '',
			city_name: '',
			branch_name: '',
			proList: [],
			cityList: [],
			city: {},
			store: {},
			storeList: [],
			deviceList: [
				{
					value: '0',
					label: '不匹配',
				},
				{
					value: '1',
					label: '有手机',
				},
				{
					value: '2',
					label: '无手机',
				},
			],
			ABOList: [
				{
					value: 'O',
					label: 'O',
				},
				{
					value: 'A1',
					label: 'A1',
				},
				{
					value: 'A2',
					label: 'A2',
				},
				{
					value: 'B1',
					label: 'B1',
				},
				{
					value: 'B2',
					label: 'B2',
				},
				{
					value: 'A1B1',
					label: 'A1B1',
				},
				{
					value: 'A2B1',
					label: 'A2B1',
				},
				{
					value: 'A1B2',
					label: 'A1B2',
				},
				{
					value: 'A2B2',
					label: 'A2B2',
				},
			],
		};
	},
	computed: {
		nowDate() {
			let d = new Date();
			return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日 `;
		},
		staff() {
			return this.$store.getters.userInfo.name;
		},
	},
	watch: {
		// 如果 branchArr 发生改变，这个函数就会运行
		member_type_Array() {
			this.Member.member_type_id = this.member_type_Array[0];
			this.Member.member_card_id = this.member_type_Array[1];
		},
	},
	methods: {
		// 顾客类型
		changeType() {
			this.typeItemList = this.typeItem[this.Member.member_type1];
			this.type_item_ids = [];
		},
		changeTypeItem(value) {
			this.Member.member_type2 = JSON.stringify(value);
		},
		changeItems() {
			if (!this.Member.member_type1) {
				this.$Message.warning('请先选择顾客类型');
			}
		},
		// 根据省份选择城市
		changePro() {
			this.cityList = this.city[this.Member.pid];
			this.Member.cid = '';
		},
		// 根据城市选择门店
		changeCity() {
			this.storeList = this.store[this.Member.cid];
			this.Member.branch_id = '';
		},
		// 点击门店先选择城市
		changeBossBranch() {
			if (!this.Member.cid) {
				this.$Message.warning('请先选择城市');
			}
		},
		// 点击城市先选择省份
		changeBossCity() {
			if (!this.Member.pid) {
				this.$Message.warning('请先选择省份');
			}
		},
		handleBack() {
			this.$router.go(-1);
		},
		handleSubmit(name) {
			this.$refs[name].validate((valid) => {
				if (valid) {
					if (this.Member.member_type2 === '[]' || this.Member.member_type2 === []) {
						this.$Message.error('请选择顾客类型');
						return;
					}
					this.Member.social = JSON.stringify(this.socialArr);
					this.Member.interest = JSON.stringify(this.interestArr);
					this.Member.contribution = JSON.stringify(this.contributionArr);
					this.Member.honor = JSON.stringify(this.honorArr);
					// delete this.Member.cid
					if (this.Member.conhabit === '0' || this.Member.conhabit === '') {
						this.Member.density_grade = '';
						this.Member.density_detail = '';
						this.densityData = [];
						MemberService.createMember(this.Member).then(() => {
							this.$router.go(-1);
						});
					}
					if (this.Member.conhabit === '1' && this.densityData.length > 0) {
						MemberService.createMember(this.Member).then(() => {
							this.$router.go(-1);
						});
					} else {
						this.conhabitEdit = true;
						// this.$Message.error('请选择密度')
					}
				} else {
					this.$Message.error('*号为必填项!');
				}
			});
		},
		handleConhabit(value) {
			if (value === '1') {
				this.choConhabit = this.conhabitEdit = true;
				this.Member.density_grade = '';
			} else {
				this.choConhabit = this.conhabitEdit = false;
				this.densityData = [];
				this.Member.density_grade = '';
			}
		},

		handleGrade(value) {
			this.Member.grade_id = value;
		},
		handleChangeDensity(value) {
			this.Member.density_grade = value[0];
			this.Member.density_detail = value[1];
			this.conhabitEdit = false;
		},
		handleNative(value, selectedData) {
			// this.Member.native_place = selectedData.map(o => o.label).join(',')
			this.Member.native_place = selectedData.map((o) => o.value).join(',');
		},
		handleInterest(value) {
			this.interestArr = value;
		},
		handleSocial(value) {
			this.socialArr = value;
		},
		handleContribution(value) {
			this.contributionArr = value;
		},
		handleHonor(value) {
			this.honorArr = value;
		},
		handleUploadSuccess(response, file) {
			this.avatarShow = file;
			this.photoData.photo = response.data.photo;
			this.photoData.id = this.Member.head_id = response.data.id;
			this.$Message.warning('头像上传成功！');
		},
		handleFormatError(file) {
			this.$Message.warning('文件 ' + file.name + ' 格式不正确，请上传 jpg 或 png 格式的图片。');
		},
		handleMarita(value) {
			this.Member.marita = value;
		},
		handleEducation(value) {
			this.Member.education = value;
		},
		handleIDBlur() {
			const value = this.Member.id_card;
			if (
				/^[1-9][0-9]{5}([1][9][2][2-9]|[1][9][3-9][0-9]|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/.test(
					value,
				)
			) {
				const day = value.substr(6, 8);
				this.Member.birthday = `${day.substr(0, 4)}-${day.substr(4, 2)}-${day.substr(6)}`;
				this.Member.age = new Date().getFullYear() - day.substr(0, 4);
				this.Member.gender = +value.substr(16, 1) % 2 === 0 ? '1' : '0';
			} else {
				this.Member.birthday = '';
				this.Member.age = 0;
			}
		},
		handleClear() {
			this.Member.age = '';
			console.log(this.Member.age);
			this.Member.birthday = '';
		},
		getUsers() {
			familyService.getAllUsers().then((data) => {
				this.userList = data.list;
			});
		},
	},
	created() {
		MemberService.getBase({ new: 1 }).then((data) => {
			this.nationList = data.nation;
			this.densityGradeList = data.density;
			this.educationList = data.education;
			this.beliefList = data.belief;
			this.maritaType = data.marital;
			this.jobList = data.p_job;
			this.memberTypeData = data.member_type;
			// this.gradeList = data.grade
			data.grade.forEach((item) => {
				this.gradeList.unshift(item);
			});
			this.interestList = data.interest;
			this.socialList = data.social;
			this.contributionList = data.special;
			this.honorList = data.honor;
			this.pTitleList = data.p_title;
			this.CityBranch = data.new_branch.need_branch;
			this.UrbanList = data.new_branch.need_city;
			this.proList = data.new_staff_list.pro;
			this.city = data.new_staff_list.city;
			this.store = data.new_staff_list.store;
			this.typeList = data.member_type.list_type1;
			this.Member.member_type1 = 6; // 默认为 4新顾客
			this.typeItem = data.member_type.list_type2;
			this.typeItemList = this.typeItem[this.Member.member_type1];
			this.type_item_ids = [14]; // 默认为 新客户
			this.Member.member_type2 = JSON.stringify(this.type_item_ids);
		});
		// 籍贯
		this.nativePlaceList = City;
		this.power = localStorage.getItem('userPower');
		if (this.power.indexOf('会员管理--查看所属店面会员信息权限') > -1) {
			this.Member.cid = this.$store.getters.userInfo.branch[1];
			this.Member.branch_id = this.$store.getters.userInfo.branch[2];
			this.pro_name = this.$store.getters.userInfo.pro_name;
			this.city_name = this.$store.getters.userInfo.city_name;
			this.branch_name = this.$store.getters.userInfo.branch_name;
			this.powerShow = true;
		} else {
			this.powerShow = false;
		}
		this.getUsers();
	},
};
</script>

<style lang="css" scoped>
.new-member-info {
  margin-top: 15px;
}
.new-member-info .symbol-box {
  padding: 30px 0;
}
.new-member-info .ivu-btn-primary {
  background: #5886e6;
  border-color: #5886e6;
  margin-right: 35px;
}
.new-member-info .ivu-btn-large {
  padding: 0 48px;
}
.card-container .ivu-card-head > p {
  color: #fff;
}
.card-container .card-box {
  position: relative;
}
.card-container .ivu-col {
  margin-bottom: 20px;
}
.card-container .card-avatar {
  padding: 10px 0;
}
.card-container .card-img img {
  border-radius: 100%;
  cursor: pointer;
}
.card-container .card-text {
  text-align: center;
  padding-top: 8px;
}
.card-container .card-info {
  padding-top: 30px;
}
.card-container .ivu-form-item {
  margin-bottom: 0;
}
.point {
  display: inline-block;
  margin-right: 4px;
  line-height: 1;
  font-family: SimSun;
  font-size: 14px;
  color: #ff3300;
  position: absolute;
  left: -10px;
  top: 10px;
}
</style>
